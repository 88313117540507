import { Field, Form, Formik } from 'formik';
// import { Link } from 'gatsby';
import { Redirect } from "@reach/router";
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import FormHandler from '../../components/FormHandler/FormHandler';
import FieldInfo from '../../components/Formular/FieldInfo/FieldInfo';
import Layout from '../../components/Layout/Layout';
import Button from '../../components/UI/Button/Button';

const ErfahrungsberichtKommentar = (props) => {
  if(!props.location.state) {
    return <Redirect noThrow to={`/hersteller/`} />;
  }
  const manufacName = props.location.state.manufacName;
  const reviewData = props.location.state.reviewData;
  const reviewDataString = `${manufacName} | ${reviewData.from} | ${reviewData.date}`;

  return (


    <Layout headlines={[manufacName]}>
      <h2>{reviewData.from}s Erfahrungsbericht kommentieren</h2>
      <p>Am {reviewData.date} schrieb {reviewData.from} folgendes:</p>
      <blockquote>
        <p dangerouslySetInnerHTML={ {__html: reviewData.text } }></p>
      </blockquote>
      <FormHandler
        sendTo='php/sendContactMail.php'
        requiredInputs={['email', 'message', 'dsgvo']}
        formType="reviewComment"
        manufacName={manufacName}
      >
        {({ formikAttr, setClassDynamically, state, FHstyle }) => (
          <Formik
            {...formikAttr}
            initialValues={{ email: '', name: '', message: '', reviewData: reviewDataString }}
          >
            {({ isSubmitting }) => (
              <Form className={FHstyle.Form} >
                <div
                  style={{
                    display: (state.dataSendSuccess ? "none" : "inherit"),
                    opacity: (state.sendingData ? "0.5" : "initial")
                  }}
                >
                  <p>Nachfolgend kannst du deinen Kommentar hinterlassen:</p>
                </div>
                <fieldset
                  disabled={state.sendingData}
                  // style={state.dataSendSuccess ? {display:'none'}:  null}
                  style={{
                    display: (state.dataSendSuccess ? "none" : "inherit"),
                    opacity: (state.sendingData ? "0.5" : "initial")
                  }}
                >

                  {/* <label htmlFor="name">Dein Name</label> */}
                  <FieldInfo
                    label={{ for: 'name', text: 'Dein Name' }}
                    className={FHstyle.hasFloatLabel}
                  >
                    <Field className={setClassDynamically('name')} type="text" name="name" placeholder=" " />
                    {/* <ErrorMessage name="name" component="div" /> */}
                  </FieldInfo>

                  <FieldInfo
                    label={{ for: 'email', text: 'E-Mail*' }}
                    className={FHstyle.hasFloatLabel}
                  >
                    <Field className={setClassDynamically('email')} type="email" name="email" placeholder=" " />
                  </FieldInfo>

                  <FieldInfo
                    label={{ for: 'message', text: 'Dein Kommentar*' }}
                    className={FHstyle.hasFloatLabel}
                  >
                    {/* <Field className={setClassDynamically('message')}  type="text" name="message" placeholder=" " /> */}
                    <Field
                      name="message"
                      render={({ field }) => (
                        <TextareaAutosize
                          {...field}
                          className={[setClassDynamically("message", this)].join(' ')}
                          placeholder=" "

                        />
                      )}
                    />
                  </FieldInfo>
                  <Field className={setClassDynamically('email')} type="hidden" name="commentData" />

                  <p> Zum Zwecke der Bearbeitung und damit wir dich bei Veröffentlichung deines Kommentares kontaktieren können, werden deine Angaben bei uns gespeichert. Weiteres erfährst du
                  in unserer Datenschutzerklärung.</p>
                  <label className={FHstyle.labelCheckbox} >
                    <Field className={setClassDynamically('dsgvo', 'checkbox')} type="checkbox" name="dsgvo" />
                    <span >
                      Ja, ich bin mit dem Inhalt der {" "}
                      <a target="_blank" href='/datenschutz/'>Datenschutzerklärung</a> einverstanden.
                  </span>
                  </label>
                  <p><br />
                    Sobald wir deinen Kommentar überprüft und veröffentlicht haben, schreiben wir dir eine Nachricht
                    an die von dir hinterlassene E-Mail-Adresse.
                  </p>
                  <Button color="black">
                    <button type="submit" disabled={isSubmitting}>
                      Senden
                  </button>
                  </Button>
                </fieldset>
              </Form>
            )}
          </Formik>)}
      </FormHandler>
    </Layout>
  )
}

export default ErfahrungsberichtKommentar;

export function Head() {
  return (
    <>
      <meta name="robots" content="noindex" />
    </>
  )
}